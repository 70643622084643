<template>
  <CRow>
    <c-button color="secondary" size="lg"><a :href="pdfSource" target="_blank" > View </a></c-button>
    <iframe className="doc" :src="pdfSource" style="border: none"/>
  </CRow>

</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import {profilerDocument} from "/src/store/url.js";

Vue.use(VueForm);
export default {
  name: "Viewer",
  mixins: [Mixin],
  data() {
    return {
      self: this,
      isLoading: true,
      module: profilerDocument,
      page: null,
      pageCount: 1,
      pdfSource: "",
      showAllPages: true,
    };
  },
  validations: {},
  created() {
    store.commit("showLoader", false); // Loader Off
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    axios.get(this.viewUrlApi(this.module, id)).then((response) => {
      if (response.data) {
        let responseData = response.data.data;
        self.pdfSource = responseData.docUrl + "?embedded=true#toolbar=0";
      }
    });
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
  },
};
</script>
<style>
iframe {
  width: 100%;
  height: 500px;
}

canvas {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  width: 1000px !important;
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}

.app-content {
  padding: 24px 16px;
}

.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b {
  background: rgba(147, 147, 147, 0.7);
}

.right {
  float: right;
}
</style>
